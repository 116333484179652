.page-footer {
  position: fixed;
  /* display: none; */
  bottom: 0;
  width: 100%;
  border-top: 1px solid rgb(137, 137, 137);

}

#printManifestContainer {
  break-before: page;
}

#printVoucherContainer {
  break-after: page;
}

.page-header {
  /* position: fixed; */
  display: none;
  top: 0mm;
  width: 100%;
  border-bottom: 1px solid rgb(137, 137, 137);
}

#printManifestContainer .MuiTypography-root {
  zoom: 0.75;
}

#printVoucherContainer .MuiTypography-root {
  zoom: 0.75;
}

/* #shipmentsTableContainer tbody tr:nth-child(6n){
  break-after: page;
} */

#manifestPrintTable tr {
  page-break-inside: avoid;
}

@media screen {

  .page-header-space {
    /* position: fixed; */
  }

  .page-header {
    position: initial;
  }
}

.page-header-space,
.page-footer-space {
  /* visibility: hidden; */
}

.page-footer-space {
  visibility: hidden;
}

@media print {
  * {
    color: black !important;
    -webkit-print-color-adjust: exact !important;
    border-color: rgb(69, 69, 69) !important;
  }

  @page {
    margin: 0.4in;
  }


  /* .page-content,
  .page-content * {
    height: initial !important;
  } */

  .page-content {
    /* height: 73.5% !important; */
  }

  .page-header,
  .page-header-space {
    overflow: hidden;
    max-height: 12cm;
  }

  .page-footer,
  .page-footer-space {
    white-space: "pre-line";
    /* height: 0.8cm !important; */

  }

  table {
    width: 100%;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group !important;
  }

  button {
    display: none;
  }

  body {
    margin: 0;
  }
}